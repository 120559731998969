import { Link } from "react-router-dom"

export const NotFound = () => {
  return (
    <div className='not_found_wrapper'>
      <div className='not_found'>
        <img src='images/necoarc.png' alt='' />
        <div className='not_found_text'>
          <p>Такої сторінки не існує, burunyaaa!</p>
          <Link to='/'>Повернутись на головну</Link>
        </div>
      </div>
    </div>
  )
}