import { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom'
import { Link } from 'react-router-dom';
import { Header } from '../../components/Header/Header';

export const MainSection = () => {
  const location = useLocation();
  const [currentPath, setCurrentPath] = useState<string>(location.pathname);

  useEffect(() => {
    setCurrentPath(location.pathname)
  })

  return (
    <div
      className='main_section'
      style={{ backgroundImage: 'url(images/main-bg.jpg)' }}
    >
      <div className='container'>
        <Header />
        <div className='main_section_content'>
          <div className='main_section_img'>
            <img src='images/main-img.png' alt='' />
          </div>
          <div className='main_section_text'>
            <h1 className='main_section_title'>Glass Moon</h1>
            <p className='main_section_descr'>Ми команда ентузіастів, яка займається озвученням й субтитруванням ваших улюблених <s>та не дуже</s> аніме.</p>
            <Link to={ currentPath === '/catalog' ? '/' : '/catalog' } className='section_link'>
              <img src='./images/right-arrow.png' alt='' />
              <span>{ currentPath === '/catalog' ? 'На головну' : 'Каталог' }</span>
            </Link>
          </div>
        </div>
      </div>
    </div>
  )
}