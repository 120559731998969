import { FC } from 'react';
import { AnimeItem } from '../../../types';

type Props = {
  year: string;
  list: AnimeItem[];
}

export const CatalogListBlock: FC<Props> = ({ year, list}) => {
  return (
    <div className='catalog_list_block'>
      <div className='catalog_list_block_title'>{year}</div>
      <ul>
        {
          list.map(item => {
            return (
              <li key={Math.random()}>
                <div className='catalog_list_item_name'>{item.name}</div>
                { item.links && (
                  <div className='catalog_list_item_links'>
                    { item.links.telegram && <a href={item.links.telegram} target='_blank' rel='noopener noreferrer'>🔗 Telegram</a> }
                    { item.links.toloka && <a href={item.links.toloka} target='_blank' rel='noopener noreferrer'>🔗 Toloka</a> }
                  </div>
                )}
                
              </li>
            )
          })
        }
      </ul>
    </div>
  )
}