import { useEffect, useState } from 'react';
import { MainSection } from '../features/MainSection/MainSection'
import { Catalog } from '../features/Catalog/Catalog'
import { Footer } from '../components/Footer/Footer'
import { ScrollToTop } from '../components/ScrollToTop/ScrollToTop';

export const CatalogPage = () => {
  const [isShowScrollBtn, setIsShowScrollBtn] = useState<boolean>(false);

  useEffect(() => {
    document.title = 'Glass Moon | Каталог';
  }, []);

  useEffect(() => {
    const handleScrollButtonVisibility = () => {
      window.scrollY > 500 ? setIsShowScrollBtn(true) : setIsShowScrollBtn(false);
    }

    window.addEventListener('scroll', handleScrollButtonVisibility);

    return () => {
      window.removeEventListener('scroll', handleScrollButtonVisibility);
    }
  }, [])

  return (
    <>
      <MainSection />
      <Catalog />
      <Footer />
      { isShowScrollBtn && <ScrollToTop /> }
    </>
  )
}