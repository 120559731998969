export const ScrollToTop = () => {
  const handleScrollToTop = () => {
    window.scrollTo({ top: 0, behavior: 'smooth' });
  }

  return (
    <button className='scroll_to_top_btn' onClick={handleScrollToTop}>
      <img src='images/arrow.png' alt='Scroll to top' />
    </button>
  )
}