import { useEffect, useState } from 'react';
import Select from 'react-select';
import { AnimeList, YearSelectOptions } from '../../types';
import { CatalogListBlock } from './components/CatalogListBlock';

export const Catalog = () => {
  const [list, setList] = useState<AnimeList>({});
  const [years, setYears] = useState<string[]>([]);
  const [selectedYear, setSelectedYear] = useState<string>('all');
  const [selectOptions, setSelectOptions] = useState<YearSelectOptions[]>([{ value: 'all', label: 'Усі' }])


  useEffect(() => {
    fetch('js/catalog.json')
      .then(res => res.json())
      .then(res => {
        setList(res)
        setYears(Object.keys(res).sort((a: any, b: any) => b - a))
      })
      .catch(err => {
        console.log(err);
      })
  }, [])

  useEffect(() => {
    let tempArr: YearSelectOptions[] = [];
    years.forEach(item => {
      tempArr.push({ value: item, label: item });
    });
    setSelectOptions([...selectOptions, ...tempArr])
  }, [years])

  const handleChangeSelect = (e: any) => {
    setSelectedYear(e.value);
  }

  return (
    <div className='catalog_section'>
      <div className='container'>
        <h3 className='section_title'>Перелік озвучених та перекладених аніме серіалів та OVA</h3>
        <div className='year_select_wrap'>
          <p>Обрати рік:</p>
          <Select
            className='year_select'
            options={selectOptions}
            defaultValue={selectOptions[0]}
            onChange={(e) => handleChangeSelect(e)}
            isSearchable={false}
          />
        </div>
        { selectedYear === 'all' ? (
            years.map(item => {
              return <CatalogListBlock year={item} list={list[item]} key={Math.random()} />
            })
          ) : (
            <CatalogListBlock year={selectedYear} list={list[selectedYear]} key={Math.random()} />
          )
        }
      </div>
    </div>
  )
}