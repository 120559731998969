import { FC } from 'react';

export const Contacts: FC = () => {
  return (
    <div
      className='contacts_section'
      style={{ backgroundImage: 'url(images/contacts-bg.jpg)' }}
    >
      <div className='container'>
        <div className='constacts_section_content'>
          <div className='contacts_section_img'>
            <img src='./images/contacts.png' alt='Contacts' />
          </div>
          <div className='contacts_block'>
            <h3 className='contacts_title'>Наші Контакти</h3>
            <ul className='contacts_list'>
              <li>
                <a href='mailto:glassmoonanime@proton.me' target='_blank' rel='noopener noreferrer'>Email</a>
              </li>
              <li>
                <a href='https://t.me/gwean_maslinka' target='_blank' rel='noopener noreferrer'>Telegram</a>
              </li>
              <li>
                <a href='https://instagram.com/glassmoonua?igshid=MzMyNGUyNmU2YQ==' target='_blank' rel='noopener noreferrer'>Instagram</a>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  )
}